import React from "react";
import CarouselPlaceholder from "../../images/carousel-placeholder.png";

function Carousel() {
  return (
    <div className="w-full my-5 mb-20">
      <div className="text-2xl sm:text-5xl font-extrabold text-mca-grey px-5 sm:px-20">
        Our <span className="text-mca-green">Initiatives</span>
      </div>
      <div className="w-full relative my-5 h-[50vh]">
        <div className="h-full w-full z-10">
          <img
            src="https://images.unsplash.com/photo-1460794418188-1bb7dba2720d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
            className="h-full w-full object-cover"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full my-5 mb-20">
      <div className="text-2xl sm:text-5xl font-extrabold text-mca-grey px-5 sm:px-20">
        Our <span className="text-mca-green">Initiatives</span>
      </div>
      <div className="w-full relative my-5 h-[40vh]">
        <div className="h-full w-full z-10">
          <img
            src={CarouselPlaceholder}
            className="h-full w-full object-cover"
          />
        </div>
        <div className="h-full w-full absolute top-0 left-0 z-20 flex items-stretch justify-between">
          <div className="text-white w-[50px] sm:w-[80px] flex items-center justify-center transition cursor-pointer hover:bg-opacity-20 hover:bg-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 sm:h-8 sm:w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </div>
          <div className="text-white w-[50px] sm:w-[80px] flex items-center justify-center transition cursor-pointer hover:bg-opacity-20 hover:bg-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 sm:h-8 sm:w-8 rotate-180"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

type InitativeProps = {
  name: string;
  subtitle: string;
  slug: string;
  image: string;
};

const Initiative: React.FC<InitativeProps> = ({
  name,
  subtitle,
  slug,
  image,
}) => {
  return (
    <div className="rounded-xl h-[300px] relative">
      <div className="h-full w-full z-10">
        <img src={image} className="h-full w-full object-cover rounded-xl" />
      </div>
      <div className="w-full p-5 rounded-xl bg-mca-grey-3 z-20 absolute left-0 bottom-0 flex flex-col gap-y-3 -mb-[5px]">
        <div>
          <div className="text-xl font-bold">{name ?? ""}</div>
          <div className="text-mca-grey-2">{subtitle ?? ""}</div>
        </div>
        <div className="flex justify-end items-center">
          <div>
            <a
              href={`/initiatives/${slug}`}
              className="block bg-mca-green px-3 py-1 text-xs uppercase text-mca-off-white rounded-full font-bold cursor-pointer"
            >
              Read More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Initiatives: React.FC<{ initiatives: { [key: string]: string }[] }> = ({
  initiatives,
}) => {
  return (
    <div className="my-10 sm:my-16 max-w-screen-xl w-full mx-auto px-5">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-10">
        {initiatives.map(({ name, subtitle, slug, gallery }, i) => (
          <Initiative
            key={i}
            {...{
              name,
              subtitle,
              slug,
              image:
                gallery.length > 0 ? gallery[0].image : CarouselPlaceholder,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export { Carousel, Initiatives };
