import * as React from "react";
import Footer from "../components/Footer";
import { Carousel, Initiatives } from "../components/OurInitiatives";
import Navbar from "../components/Navbar";
import { graphql } from "gatsby";

const OurInitiativesPage = ({ data }) => {
  console.log(data.initiatives.initiatives);
  return (
    <div>
      <Navbar />
      <Carousel />
      <Initiatives initiatives={data.initiatives.initiatives} />
      <Footer />
    </div>
  );
};

export default OurInitiativesPage;

export const query = graphql`
  query OurInitiativesQuery {
    initiatives {
      initiatives {
        description
        gallery {
          image
          name
        }
        name
        related_posts {
          title
          subtitle
          slug
          read_time
          featured_image
          content
          author {
            avatar
            designation
            email
            name
            twitter
          }
        }
        slug
        subtitle
      }
    }
  }
`;
